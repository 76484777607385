export default [
  {
    name: 'Leila',
    quote: `Your ladder to go from zero to hero, true that.`,
  },
  {
    name: 'Mark',
    quote: `I keep coming back for more foundation knowledge. Save me a ton of time searching. Instead of solving the problem for me, the tuts taught me to lead myself to the solutions.`,
  },
  {
    name: 'Dan',
    quote: `Awesome tutorials, please keep up with the great work.`,
  },
  {
    name: 'Dolores',
    quote: `Best classes ever. These should be made official in universities.`,
  },
]
